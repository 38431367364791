import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer
} from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
        <div>
        <h1 id="copyrightpolicy">Copyright Policy</h1>
        <p><em>Effective Date: November 24, 2019</em></p>
        <p>AirPage respects intellectual property rights and expects its users to do the same. AirPage responds expeditiously to claims of copyright infringement committed using the Services. In accordance with applicable law (including the <a href="http://www.copyright.gov/legislation/pl105-304.pdf">Digital Millennium Copyright Act of 1998<span>)</span></a>, AirPage will, under appropriate circumstances, terminate the accounts of repeat copyright infringers. We also reserve the right, in our sole discretion, to terminate any account for actual or apparent copyright infringement. Note that any capitalized terms not defined in this Copyright Policy have the meanings set forth in our <a href="/terms-of-service/">Terms of Service</a>.</p>
        <p><strong>Submitting A Notice Of Infringement.</strong> To submit a notice of claimed copyright infringement, you will need to provide us with the following information:</p>
        <p>1.&nbsp; Identification of the copyrighted work claimed to have been infringed (e.g., a link to your original work or clear description of the materials allegedly being infringed upon);</p>
        <p>2.&nbsp; Identification of the infringing material and information reasonably sufficient to permit AirPage to locate the material on the Services;</p>
        <p>3.&nbsp; Your contact information, including name, address, telephone number and email address;</p>
        <p>4.&nbsp; The following statements:</p>
        <p>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.”</p>
        <p>“I hereby state that the information in this notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”; and</p>
        <p>5.&nbsp; A physical or electronic signature (typing your full name will suffice) of the copyright owner or a person authorized to act on their behalf.</p>
        <p>Deliver this notice, with all items completed, to <a href="mailto:copyright@airpage.com.">copyright@airpage.com.</a> Alternatively, you may mail the notice to:</p>
        <p>AirPage, Inc. <br />
            Attention: Copyright Agent <br />
            1830 Palisades Dr. <br />
            Pacific Palisades, CA 90272</p>
        <p>AirPage’s response to notices of alleged copyright infringement may include the removal or restriction of access to allegedly infringing material. Please note that information provided in a notice of copyright infringement may be forwarded to the user who posted the allegedly infringing content.</p>
        <p><strong>Bad Faith Notices.</strong> Please be aware that under applicable law (including 17 U.S.C. § 512(f)), you may be liable for any damages, including without limitation costs and attorneys’ fees incurred by us or our users, if you knowingly materially misrepresent that material or activity is infringing. If you’re unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.</p>
        <p><strong>Submitting A Counter-Notification.</strong> If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, you may send us a counter-notice. To submit a counter-notice, please respond to our original email notification of removal with the following information:</p>
        <p>1.&nbsp; Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled (the description from the copyright notice will suffice);</p>
        <p>2.&nbsp; Your name, address and telephone number;</p>
        <p>3.&nbsp; A statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located (or the federal district courts located in Los Angeles County, California if your address is outside of the United States), and that you will accept service of process from the person who provided the original notification or an agent of such person;</p>
        <p>4.&nbsp; The following statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled."; and</p>
        <p>5.&nbsp; A physical or electronic signature (typing your full name will suffice).</p>
        <p>Upon receipt of a valid counter-notification, AirPage will forward it to the notifying party who submitted the original notice of infringement. The notifying party will then have ten business days to notify us that they have filed legal action relating to the allegedly infringing material. If we don’t receive any such notification within ten business days, we may restore the material to the Services.</p>
        <p>We may modify this Copyright Policy from time to time, and will post the most current version on our site.</p>
        </div>
    </LegalContainer>
  </MainLayout>
);
